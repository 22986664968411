@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --logo-container-size: 60px;
  --logo-inner-size: 48px;
  --logo-inner-size-shrunk: 38px;
  --blue-line-width: 7.2px;
  --white-line-width: 19.2px;
  --white-line-width-shrunk: 12px;
  --logo-dark-blue: #0a1c3a;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html {
  color-scheme: light;
}
body {
  color: black;
  background: white;
}

/** override headless ui **/
#headlessui-portal-root {
  position: absolute;
}

::-webkit-calendar-picker-indicator {
  height: 20px;
  width: 20px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" /></svg>');
  cursor: pointer;
}

.dark ::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" /></svg>');
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

/**  Start of Animated logo styling **/

.loader-logo-background {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #fff;
}

.dark .loader-logo-background {
  background-color: #000;
}

.loader-logo-container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--logo-dark-blue);
  position: relative;
  width: var(--logo-container-size);
  height: var(--logo-container-size);
  border-radius: 7.2px;
  animation: rotate-animation 2s infinite;
}

.loader-logo-container {
  position: sticky;
  background-color: var(--logo-dark-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--logo-inner-size);
  width: var(--logo-inner-size);
  overflow: hidden;
  animation: shrink-container 2s infinite;
}

.loader-white-lines,
.loader-blue-lines {
  height: 68px;
  margin: 2.4px;
  position: absolute;
}

.loader-blue-lines {
  width: var(--blue-line-width);
  background-color: var(--logo-dark-blue);
  animation: shrink-blue-lines 2s infinite;
}

.loader-white-lines {
  width: var(--white-line-width);
  background-color: white;
  animation: shrink-white-lines 2s infinite;
}
@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }

  33%,
  100% {
    transform: rotate(90deg);
  }
}

@keyframes shrink-container {
  0%,
  33% {
    height: var(--logo-inner-size);
    width: var(--logo-inner-size);
  }
  50%,
  83% {
    height: var(--logo-inner-size-shrunk);
    width: var(--logo-inner-size-shrunk);
  }
  100% {
    height: var(--logo-inner-size);
    width: var(--logo-inner-size);
  }
}

@keyframes shrink-blue-lines {
  0%,
  33% {
    width: var(--blue-line-width);
  }
  50%,
  83% {
    width: 0;
  }
  100% {
    width: var(--blue-line-width);
  }
}

@keyframes shrink-white-lines {
  0%,
  33% {
    width: var(--white-line-width);
  }
  50%,
  83% {
    width: var(--white-line-width-shrunk);
  }
  100% {
    width: var(--white-line-width);
  }
}

.line-1 {
  transform: rotate(45deg);
}

.line-2 {
  transform: rotate(-45deg);
}

/**  End of Animated logo styling **/
